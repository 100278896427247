import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {ACCOUNT_STATUS_INITIAL, ACCOUNT_STATUS_LOADED, ACCOUNT_STATUS_LOADING} from "~/constants/Account";
import type {AccountInitialState} from "~/@types/reducers/account";
import {enhancedAuthAPI} from "~/api/enhancedAuthAPI";
import {enhancedUserAPI} from "~/api/enhancedUserAPI";
import {UsercommonStatus} from "~/@types/api/authAPI";

const initialState: AccountInitialState = {
    id: 0,
    state: null,
    status: null,
    username: undefined,
    "auth-token": null,
    "jwt-token": null,
    "restricted-pages": [],
};

const anonymousState: AccountInitialState = {
    ...initialState,
    status: UsercommonStatus.Anonymous,
    username: UsercommonStatus.Anonymous,
};

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        init: (state) => ({
            ...state,
            state: ACCOUNT_STATUS_INITIAL,
        }),
    },
    extraReducers: builder => {
        builder.addMatcher(
            isAnyOf(enhancedUserAPI.endpoints.getUserByUserId.matchPending),
            (state, {meta: {arg: {originalArgs: userID}}}) => {
                return userID === "current"
                    ? {
                        ...state,
                        state: ACCOUNT_STATUS_LOADING
                    }
                    : state;
            }
        );
        builder.addMatcher(
            enhancedUserAPI.endpoints.getUserByUserId.matchFulfilled,
            (state, {payload: user, meta: {arg: {originalArgs: userID}}}) => {
                return userID === "current"
                    ? {
                        ...state,
                        ...(user || {}),
                        state: ACCOUNT_STATUS_LOADED,
                    }
                    : state;
            }
        );
        builder.addMatcher(
            enhancedUserAPI.endpoints.getUserByUserId.matchRejected,
            (state, {meta: {arg: {originalArgs: userID}}}) => {
                return userID === "current"
                    ? anonymousState
                    : state;
            }
        );
        builder.addMatcher(
            enhancedAuthAPI.endpoints.postAuthSignIn.matchFulfilled,
            (state, {payload: auth}) => {
                return auth["user-id"] && auth["jwt-token"]?.token
                    ? {
                        ...state,
                        status: auth["status"] || UsercommonStatus.Active,
                        id: auth["user-id"],
                        "jwt-token": auth["jwt-token"],
                    } as AccountInitialState
                    : state;
            }
        );
        builder.addMatcher(
            enhancedAuthAPI.endpoints.deleteAuthSignOut.matchFulfilled,
            () => anonymousState
        );
    },
    selectors: {
        getAccountStatus: state => state.status,
        getAccountState: state => state.state,
        isAnonymous: state => isStateAnonymous(state),
        getRestrictedPages: state => state["restricted-pages"],
        getAuthToken: state => state["auth-token"],
        getJWTToken: state => state["jwt-token"],
        getAccountGreetingName: state => {
            return state["first-name"] || state.email?.substring(0, state.email?.indexOf("@"));
        },
        getCurrentUser: state => isStateAnonymous(state) ? undefined : state,
    }
});

function isStateAnonymous(state: AccountInitialState): boolean {
    return state.id === 0 || !state.username || !state.state || state.status === UsercommonStatus.Anonymous;
}

export const {init} = accountSlice.actions;

export const {
    getAccountState,
    getAccountStatus,
    getAuthToken,
    getJWTToken,
    isAnonymous,
    getCurrentUser,
    getAccountGreetingName,
} = accountSlice.selectors;

export default accountSlice;