import {authAPI} from "~/api/authAPI";
import {TagAuth} from "~/constants/Tags";

export const enhancedAuthAPI = authAPI.enhanceEndpoints({
    addTagTypes: [TagAuth],
    endpoints: {
        postAuthSignIn: {invalidatesTags: [{type: TagAuth, id: "current"}]},
        deleteAuthSignOut: {invalidatesTags: [{type: TagAuth, id: "current"}]},
        deleteAuthSession: {invalidatesTags: [{type: TagAuth, id: "current"}]},
        deleteAuthSessionBySessionId: {invalidatesTags: [{type: TagAuth, id: "current"}]},
    }
});

export const {
    useGetAuthOauthByProviderAndModeQuery,
    useLazyGetAuthOauthByProviderAndModeQuery,
    useGetAuthSessionQuery,
    useLazyGetAuthSessionQuery,
    useDeleteAuthSessionMutation,
    useDeleteAuthSessionBySessionIdMutation,
    usePostAuthSignInMutation,
    useDeleteAuthSignOutMutation,
    usePostAuthSignUpMutation,
    useGetAuthSignUpDataQuery,
    useLazyGetAuthSignUpDataQuery,
    usePostAuthVerifyMutation,
} = enhancedAuthAPI;