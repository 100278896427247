import {BaseQueryFn, createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {type OpenAPI3} from "openapi-typescript";
import {type BaseQueryApi, type FetchArgs, retry as rtkRetry} from "@reduxjs/toolkit/query";
import {type ExtraOptions as APIExtraOptions} from "~/@types/api/extraAPIOptions";
import {type AccountInitialState} from "~/@types/reducers/account";
import {securitySchemes} from "~/api/extraAPIOptions";
import type {RootState} from "~/reducers";

export function getAPIKeyAuthParameter(securityOptions: APIExtraOptions["security"]): string | undefined {
    /* todo: add  security schema parsing*/
    return securityOptions && Object.keys(securityOptions).reduce<string | undefined>(
        (authParam, secName) => {
            if (!authParam && securitySchemes?.[secName]?.type === "apiKey" && securitySchemes?.[secName]?.in === "query") {
                authParam = securitySchemes?.[secName]?.name;
            }
            return authParam;
        },
        undefined
    );
}

export function prepareFetchBaseQuery(baseUrl = VITE__API_ENDPOINT, headers: [string, string][] = []) {
    const baseQuery = rtkRetry(
        async (args: string | FetchArgs, api, extraOptions) => {
            const result = await fetchBaseQuery({
                //credentials: "include",
                baseUrl,
                prepareHeaders: (fetchHeaders, {getState}) => {
                    const {
                        app: {
                            "csrf-token": csrfToken,
                            // "device-fingerprint": deviceFingerprint,
                        },
                        account: {"jwt-token": jwtToken}
                    } = getState() as RootState;

                    const headersList = [...headers];

                    if (csrfToken) {
                        headersList.unshift(["X-CSRF-Token", "" + csrfToken]);
                    }

                    if (jwtToken?.token) {
                        let expired = false;
                        try {
                            if (jwtToken.expire) {
                                const expireDate = new Date(jwtToken.expire);
                                expired = expireDate.getTime() < (new Date()).getTime();
                            }
                        } catch (_e) {
                            // do nothing. Just let server validate token
                        }
                        if (!expired) {
                            headersList.unshift(["authorization", `Bearer ${jwtToken.token}`]);
                        }
                    }

                    headersList.unshift(["X-Requested-With", "XMLHttpRequest"]);
                    //devWarn(`Fingerprint not passed ${deviceFingerprint}`);
                    //headersList.unshift(['X-Device-Fingerprint', '' + deviceFingerprint]);

                    headersList.forEach(header => fetchHeaders.set(...header));

                    return fetchHeaders;
                }
            })(args, api, extraOptions);

            if (result.error) {
                if (api.type === "mutation" || typeof result.error.status === "number" && result.error.status < 500) {
                    rtkRetry.fail(result.error, result.meta);
                }
            }

            return result;
        },
        {maxRetries: 5}
    );

    /* todo: add  security schema parsing*/
    const baseQueryFn:BaseQueryFn<any, unknown, unknown, APIExtraOptions> =  async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: APIExtraOptions) => {
        if (extraOptions && extraOptions.security) {
            const state = api.getState() as {account: AccountInitialState};
            const jwtToken = state["jwt-token"];
            if (!jwtToken) {
                const apiAuthParam = getAPIKeyAuthParameter(extraOptions.security);
                if (apiAuthParam) {
                    const authToken = state["auth-token"];
                    if (authToken) {
                        if (typeof args === "string") {
                            args = {url: args};
                        }
                        const divider = args.url.includes("?") ? "&" : "?";
                        const query = new URLSearchParams({[apiAuthParam]: authToken});
                        args.url += divider + query;
                    }
                }
            }
        }
        return baseQuery(
            args,
            api,
            {}
        );
    };

    return baseQueryFn;
}

export const baseAPI  = createApi({
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (build) => ({
        getSchema: build.query<OpenAPI3, void>(
            {query: () => ({ url: "/openapi.json?frontend=true&hide-gw=true" })}
        ),
    }),
});
export const {useGetSchemaQuery} = baseAPI;
